import React from "react";
import Typerwriter from "typewriter-effect";
import Fade from "react-reveal/Fade";

const Header = () => {
  return (
    <section id="home" className="page-section">
      <div className="container">
        <div
          className="
      home-content
      row
      d-flex
      justify-content-between
      align-items-center
      mx-auto
    "
        >
          <Fade delay={2800} duration={2000}>
            <div className="home-text-area col col-12 col-lg-6">
              <h1 className="h1 home-text--heading display-1">
                WHAT'S GOOD YOP? 
                
                BECOME APART OF THE YOP YOPILY!
              </h1>
              <h3 className="home-text--subheading h3">
                <Typerwriter
                  options={{
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter

                      .typeString("YOPPITY YOP YOPPY...")
                      .pauseFor(300)
                      .deleteChars(23)
                      .pauseFor(300)
                      .typeString("YOPPING ALL THE YOPS!")
                      .pauseFor(300)
                      .start();
                  }}
                />
              </h3>
              <p className="home-text--info lead">
                Yoppers is a fun, memeish, and legendary NFT collection created by THE YOPPIEST YOPPERS!
                This project has no roadmap HOWEVER there are YOPS! Yoppers are people who just YOP!
                Get YOP or get left losers! TIME TO GET YOPPY! YOP!
              </p>
            </div>
            
            <div className="home-img-area col col-12 col-lg-6">
              <img
                src="img/yopwhite.png" width="568" height="354"
                alt=""
                className="img-fluid home-img--hand"
              />
            </div>
          
            
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Header;
